/* eslint-disable @next/next/no-img-element */
import { useWhiteLabelLan } from "@hooks/useLayout";
import { planInitbook } from "@lib/api/renewPlans/planInitBook";
import { useCommonCheckoutStore } from "@store/commonCheckoutStore";
import { PAYMENT_PATH, WEB_SOCKET_INSPIRA_URL } from "@helpers/config";
import {
  checkPaymentWithOxxo,
  getCardPaymentReq,
  usePaymentMethodsStore,
  isAlphanumeric,
} from "@store/paymentMethodsStore";
import {
  ELEMETS_ADD_NEW_CARD,
  ELEMETS_PAY_NEW_CARD,
  INSPIRA_PG,
  STRIPE_ELEMENTS,
} from "@data/paymentData";
import { shallow } from "zustand/shallow";
import { useEffect, useRef, useState } from "react";
import {
  getCountryCode,
  getCurrencyCode,
  getHost,
  getLangCodeFull,
} from "@helpers/helper";
import { useAuth } from "@contexts/AuthContext";
import { generateCheckSum } from "@lib/api/pgv2/generateCheckSum";
import { checkCookies, getCookie } from "cookies-next";
import { paymentWithSavedCard } from "@lib/api/pgv2/paymentWithSavedCard";
import {
  HANDLE_PAY_WITH_SAVED_CARD,
  HANDLE_PAY_WITH_SAVED_CARD_URL,
  loggerServer,
} from "@lib/api/logger/setLoggerRegister";
import { paymentMethodsPgV2Store } from "@store/paymentMethodsPgV2Store";
import { getInitBookPayment } from "@lib/api/membershipUpgrade";
import InspiraPg from "@components/Pgv2/InspiraPg";
import { PaymentMethods } from "@components/Book/PaymentMethods";
// @ts-ignore
import io from "socket.io-client";
import { BOOKED, PROCESSING, PENDING } from "@data/consts";
import { useLayoutStore } from "@store/layoutStore";
import { useRouter } from "next/router";
import { useWeeksCheckoutStore } from "@store/weeksCheckoutStore";
import { fixedNumber } from "@lib/PersonalWallet/ImplementationLogic";

interface Props {
  data: DataPlansV2Type | null;
  initBookData: InitBookSubsPlanType | null;
  scrollDiv: any;
}

export default function PaymentSubscription({
  data,
  initBookData,
  scrollDiv,
}: Props) {
  const router = useRouter();
  const [bookingId, setBookingId] = useState<string>("");
  const [_payment, setPayment] = useState<boolean>(false);
  const [_showStripeElementsCardFail, setShowStripeElementsCardFail] =
    useState(false);
  const [socketData, setSocketData] =
    useState<ActivitySocketResponseType | null>(null);
  const [enabledButtonPay, setEnabledButtonPay] = useState<boolean>(true);
  const [initBookBackUp, setInitBookBackUp] =
    useState<initBookBackUpResponseType>({
      booking_id: 0,
      is_orderTokenExists: false,
      is_popup: false,
      is_popupExists: false,
      orderToken: "",
    });
  const childRef: any = useRef(null); // ! se necesita para stripe elements
  const { currentUser, isLogged } = useAuth();
  const { setOpenPlansModal, setDisableDiv } = useLayoutStore();
  const { setInitPaymentData } = paymentMethodsPgV2Store();
  const { getLangLabel } = useWhiteLabelLan();
  const { setShow3dSecure } = usePaymentMethodsStore();
  const { setIsDisableCompleteButton } = useCommonCheckoutStore();

  const { hideBtnBook } = paymentMethodsPgV2Store(
    (state) => ({
      hideBtnBook: state.hideBtnBook,
    }),
    shallow
  );

  const dataSupscriptions = data?.userProfile.subscription_details.find(
    (f) => f
  );

  const debt_payed =
    dataSupscriptions && dataSupscriptions?.subscription_debt > 0;

  const {
    generatedToken,
    selectedCreditCard,
    suppliers,
    isEnabledPGV2,
    apiKeySupplier,
  } = usePaymentMethodsStore(
    (state) => ({
      generatedToken: state.generatedToken,
      selectedCreditCard: state.selectedCreditCard,
      suppliers: state.suppliers,
      isEnabledPGV2: state.isEnabledPGV2,
      apiKeySupplier: state.apiKeySupplier,
    }),
    shallow
  );

  console.log(isEnabledPGV2, "=====> pgv2");

  const is_enable_stripe_elements = suppliers.some(
    (s) => s.id == STRIPE_ELEMENTS
  );
  const { cashCardApplied, couponCodeApplied } = useWeeksCheckoutStore(
    (state) => ({
      cashCardApplied: state.cashCardApplied,
      couponCodeApplied: state.couponCodeApplied,
    }),
    shallow
  );
  const currency = initBookData?.currency || getCurrencyCode();

  const handleSetShowStripeElementsCardFail = (state: boolean) => {
    setShowStripeElementsCardFail(state);
  };

  const checkIsRenewal = () => {
    if (data && data?.userProfile?.subscription_details.length > 0) {
      return true;
    }
    return false;
  };

  const getTotal = (
    total: number,
    cashCardApplied: number,
    couponCodeApplied: WeeksCoupponCodeType | null
  ) => {
    return Number(
      Math.max(
        0,
        Number(
          total -
            cashCardApplied -
            Number(couponCodeApplied?.applied_amount || 0)
        )
      )
    );
  };

  const proccessPGV2 = async (bookingId: string) => {
    console.log("🚀 ~ proccessPGV2 ~ bookingId:", bookingId);
    if (isEnabledPGV2) {
      if (bookingId) {
        const totalToPay = getTotal(
          initBookData?.charge_org ||
            (initBookData?.plan_charge_after &&
              initBookData?.plan_charge_after > 0)
            ? 0
            : (initBookData?.plan_amount || 0) +
                (initBookData?.subscription_debt ?? 0),
          cashCardApplied,
          couponCodeApplied
        );
        if (
          selectedCreditCard !== ELEMETS_PAY_NEW_CARD.name &&
          selectedCreditCard !== ELEMETS_ADD_NEW_CARD.name
        ) {
          const checkSumRes = await generateCheckSum({
            orderId: bookingId || "",
            currency: currency,
            countryCode: getCountryCode(),
            amount: Number(totalToPay),
            api_key: apiKeySupplier,
          });
          const paymentData = {
            country_code: getCountryCode(),
            card_id: selectedCreditCard,
            ip: "127.0.0.1",
            email: currentUser?.email || "",
            order_id: bookingId || "",
            amount: Number(totalToPay),
            currency: currency,
            description: `SUB book: ${bookingId}`,
            subscription: false,
            checksum: checkSumRes || "",
            apiKeySupplier,
            metadata: {
              bookingId,
              productType: "SUB",
              api_key: currentUser?.userToken || "",
              currency: currency,
              language_code: getLangCodeFull(),
              origin: getHost(),
              pgType: INSPIRA_PG,
              correlation_id: getCookie("correlationId"),
              org_id: checkCookies("orgid") ? getCookie("orgid") : 0,
              user_id: currentUser?.userId,
              is_renewal: checkIsRenewal(),
              charge_org: initBookData?.charge_org,
              is_trial:
                Number(initBookData?.plan_charge_after || 0) > 0 &&
                !checkIsRenewal()
                  ? true
                  : false,
              ...(debt_payed ? { debt_payed } : {}),
            },
          };
          await loggerServer({
            dataRequest: paymentData,
            processName: HANDLE_PAY_WITH_SAVED_CARD,
            url: HANDLE_PAY_WITH_SAVED_CARD_URL,
          });
          const paySavedCard = await paymentWithSavedCard(paymentData);
          await loggerServer({
            dataResponse: paySavedCard || {},
            processName: HANDLE_PAY_WITH_SAVED_CARD,
            url: HANDLE_PAY_WITH_SAVED_CARD_URL,
          });
          if (paySavedCard?.payment_id) {
            setPayment(true);
            setBookingId(bookingId);
            return false;
          } else {
            handleSetShowStripeElementsCardFail(true);
            setDisableDiv(false);
          }
        } else if (selectedCreditCard == ELEMETS_PAY_NEW_CARD.name) {
          console.log(
            "🚀 ~ proccessPGV2 ~ selectedCreditCard init:",
            selectedCreditCard
          );
          setInitPaymentData({
            amount: Number(totalToPay),
            amount_to_capture: Number(totalToPay),
            currency: currency,
            email: currentUser?.email || "",
            country_code: getCountryCode(),
            order_id: bookingId,
          });
        }
      } else {
        handleSetShowStripeElementsCardFail(true);
        setDisableDiv(false);
      }
      return isEnabledPGV2;
    } else {
      setDisableDiv(false);
    }
    return false;
  };

  const finishPaymentElements = async (
    _e?: any,
    completeBookin?: boolean
  ): Promise<string | null> => {
    if (is_enable_stripe_elements || isEnabledPGV2) {
      // Get Current plan selected by user
      // ! es necesario para stripe elements
      if (!completeBookin && generatedToken?.auth_url) {
        setShow3dSecure(true); // * se muestra modal 3dsecure
        setIsDisableCompleteButton(false); // * se desbloquea botton book
        return null;
      }
      const cardPaymentReq = getCardPaymentReq(
        Number(initBookData?.plan_amount)
      );
      //CAmbio nuevo payment
      if (!isEnabledPGV2) {
        if (
          is_enable_stripe_elements &&
          selectedCreditCard == ELEMETS_ADD_NEW_CARD?.name &&
          cardPaymentReq == null
        ) {
          /* ADD NEW CARD */
          handleSetShowStripeElementsCardFail(true);
          setIsDisableCompleteButton(false);
          return null;
        } else if (
          is_enable_stripe_elements &&
          isAlphanumeric(selectedCreditCard || "") &&
          !cardPaymentReq?.pg_token
        ) {
          /* WHEN CARD IS NOT AVAILABLE OR EXPIRADE DATE */
          handleSetShowStripeElementsCardFail(true);
          setIsDisableCompleteButton(false);
          return null;
        }
      }
      //* end
      const cashcard_currency: string = String(
        getCookie("currency_code") || "USD"
      );
      const cashcard_amount: string = String(cashCardApplied || "");
      let data1: InitBookSubsPlanElementsType = {
        is_renewal: checkIsRenewal(),
        currency: String(currency),
        plan_amount: String(initBookData?.plan_amount),
        id: Number(initBookData?.sub_plan_id),
        subscription_code: String(initBookData?.subscription_code),
        lang_code: String(getLangCodeFull() || "en-US"),
        user_type: 2,
        quot_id: "",
        pg_token: String(cardPaymentReq?.pg_token),
        pg_type: String(cardPaymentReq?.pg_type),
        is_trial:
          Number(initBookData?.plan_charge_after || 0) > 0 && !checkIsRenewal()
            ? true
            : false,
        charge_org: initBookData?.charge_org,
        cn_cd: couponCodeApplied?.coupon_code || "",
        cn_trnid: couponCodeApplied?.coupon_trn_id || "",
        cn_disc: Number(
          fixedNumber(Number(couponCodeApplied?.applied_amount || 0))
        ),
        cashcard: cashcard_currency,
        cashcard_amount: cashcard_amount,
      };
      console.log("initbok data", data1);
      // return;
      const res = await planInitbook(data1);
      console.log("respInitbook", res);
      // return;
      let is_popup: boolean = res?.data?.payment_token?.is_popup || false;
      let orderToken: string = res?.data?.payment_token?.orderToken || "";
      let booking_id: number = Number(res?.data?.booking_id || 0);
      let is_popupExists: boolean = res?.data?.payment_token?.hasOwnProperty(
        "is_popup"
      )
        ? true
        : false;
      let is_orderTokenExists: boolean =
        res?.data?.payment_token?.hasOwnProperty("orderToken") ? true : false;

      if (!initBookBackUp?.booking_id) {
        const bookingId: number = Number(res?.data?.booking_id);
        if (bookingId) {
          setBookingId(String(bookingId));
          const isPGV2 = await proccessPGV2(String(bookingId || ""));
          if (isPGV2) {
            return String(bookingId);
          }
        }
        setInitBookBackUp({
          is_popup: is_popup,
          orderToken: orderToken,
          booking_id: booking_id,
          is_popupExists: is_popupExists,
          is_orderTokenExists: is_orderTokenExists,
        });
      } else {
        is_popup = initBookBackUp?.is_popup;
        orderToken = initBookBackUp?.orderToken;
        booking_id = initBookBackUp?.booking_id;
        is_popupExists = initBookBackUp?.is_popupExists;
        is_orderTokenExists = initBookBackUp?.is_orderTokenExists;

        if (booking_id) {
          setBookingId(String(bookingId));
          const isPGV2 = await proccessPGV2(String(booking_id || ""));
          if (isPGV2) {
            return String(booking_id);
          }
        }
      }

      if (
        String(initBookData?.plan_amount) === "0" ||
        initBookData?.plan_amount === 0 ||
        initBookData?.plan_amount === 0.0 ||
        String(initBookData?.plan_amount) === "0.00"
      ) {
        /*
          Agregar algo
        */
      } else {
        const orderToken = res?.result?.payment_token?.orderToken;
        if (is_popup && booking_id && orderToken) {
          var script = document.createElement("script");
          script.setAttribute("type", "text/javascript");
          script.setAttribute("src", PAYMENT_PATH + "js/pg_index.js");
          script.setAttribute("id", "mpscript");
          script.setAttribute("data-otk", orderToken);
          document.head.appendChild(script);
        } else if (
          is_popupExists &&
          is_orderTokenExists &&
          !is_popup &&
          !orderToken &&
          booking_id
        ) {
          // ! se necesita para stripe elements
          const Oxxo: boolean = await !checkPaymentWithOxxo(childRef);
          if (
            Oxxo ||
            (cardPaymentReq?.pg_type == "oxxo" && cardPaymentReq?.pg_token)
          ) {
            setIsDisableCompleteButton(false);
            false;
            return null;
          }
          if (
            is_enable_stripe_elements &&
            cardPaymentReq?.pg_type == "stripe" &&
            cardPaymentReq?.pg_token &&
            !isAlphanumeric(selectedCreditCard || "") &&
            !generatedToken?.auth_url
          ) {
            const value = await childRef?.current?.handleSubmit();
            console.log({ value: value });
            const error_type: string = value?.error || "";
            if (error_type) {
              setIsDisableCompleteButton(false);
              return null;
            }
          }
          // ! end
          //reloadSuccess(`${booking_id}`);
        } else {
          //router.push({ pathname: "/error" });
        }
      }
      return null;
    } else {
      if (isLogged && initBookData) {
        const res = await getInitBookPayment(initBookData);
        if (initBookData.plan_amount === 0) {
          /*
              Agregar algo
            */
        } else {
          const orderToken = res?.result?.payment_token?.orderToken;
          if (orderToken) {
            var script = document.createElement("script");
            script.setAttribute("type", "text/javascript");
            script.setAttribute("src", PAYMENT_PATH + "js/pg_index.js");
            script.setAttribute("id", "mpscript");
            script.setAttribute("data-otk", orderToken);
            document.head.appendChild(script);
          } else {
            //router.push({ pathname: "/error" });
          }
        }
      }
      return null;
    }
  };

  const getExtraParams = () => {
    let value = "";
    if (initBookData?.subscription_code) {
      value = initBookData?.subscription_code;
    }

    return { subscription_code: value, is_renewal: checkIsRenewal() };
  };

  const socketInitializer = async (bookingId: string) => {
    const socketInit = io(WEB_SOCKET_INSPIRA_URL, {
      transports: ["websocket"],
    });
    console.log(
      "🚀 ~ socketInitializer ~ WEB_SOCKET_URL:",
      WEB_SOCKET_INSPIRA_URL
    );

    socketInit.on("connect", () => {
      console.log("socket connected");
      socketInit.emit("message", bookingId);
    });
    if (bookingId) {
      const channelId = `booking-${bookingId}`;
      console.log("socket channel", channelId);

      socketInit.on(channelId, (msg: ActivitySocketResponseType) => {
        console.log("socket booking client", msg);
        if (msg) {
          setSocketData({ ...msg });
        }
      });
    }
  };

  useEffect(() => {
    if (bookingId) {
      let count: number = 0;
      const interValNumber = setInterval(() => {
        count++;
        socketInitializer(String(bookingId));
        if (count == 10) {
          clearInterval(interValNumber);
        }
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingId]);

  useEffect(() => {
    setTimeout(() => {
      if (socketData?.status === BOOKED) {
        setOpenPlansModal(false);
        router.reload();
      }
    }, 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketData]);

  return (
    <>
      {initBookData && (
        <div ref={scrollDiv}>
          <PaymentMethods
            key={"subs-payment-methods-modal-pay"}
            ids={String(initBookData?.sub_plan_id ?? "")}
            productType={"SUB"}
            totalPaymentAmount={getTotal(
              initBookData?.plan_amount ?? 0,
              cashCardApplied,
              couponCodeApplied
            )}
            childRef={childRef}
            extraParamsToken={getExtraParams()}
            onCompleteBooking={finishPaymentElements}
            isDisabledCashCard={true}
            isDisabledCouponCode={false}
            isDisabledCreditCard={checkIsRenewal()}
          />

          <div className="tw-text-center px-20 my-4">
            <input
              type="checkbox"
              className="resort-form-check-input plan-form-check tw-w-5 tw-h-5 !tw-border !tw-border-gray-400"
              onChange={() => {
                setEnabledButtonPay(!enabledButtonPay);
              }}
            />
            <span className="tw-pl-4 tw-text-base tw-text-inspira-gray-100 tw-font-semibold">
              {getLangLabel(
                "I authorize my credit card to be changed automatically for my monthly membership"
              )}
            </span>
          </div>

          {bookingId && (
            <div className="tw-flex tw-items-center tw-justify-center tw-gap-3">
              <div
                className={`tw-inline-block ${
                  socketData?.status === BOOKED ? "" : "pulse-opacity"
                }`}
              >
                <img
                  src={
                    socketData?.status === BOOKED
                      ? "/images/flight/checkout-success/successIcon.svg"
                      : "/images/general/loader-spin.png"
                  }
                  className="tw-w-12"
                  alt=""
                />
              </div>
              <h1 className="tw-text-inspira-purple-500 tw-text-3xl tw-mb-0">
                {!socketData?.status || socketData?.status === PENDING
                  ? getLangLabel(String(PROCESSING).toUpperCase())
                  : getLangLabel(
                      String(socketData?.status || "").toUpperCase()
                    )}
              </h1>
            </div>
          )}

          <section>
            <InspiraPg
              key={"subs-payment-methods-pg-modal-pay"}
              nameFormPg="SUB-PAYMENT"
              productType="SUB"
              pathSuccess="/subscription-checkoutsuccess"
              initBook={finishPaymentElements}
              price={getTotal(
                initBookData?.charge_org ||
                  (initBookData?.plan_charge_after &&
                    initBookData?.plan_charge_after > 0)
                  ? 0
                  : (initBookData?.plan_amount || 0) +
                      (initBookData?.subscription_debt ?? 0),
                cashCardApplied,
                couponCodeApplied
              )}
              is_renewal={checkIsRenewal()}
              charge_org={initBookData?.charge_org}
              is_trial={
                Number(initBookData?.plan_charge_after || 0) > 0 &&
                !checkIsRenewal()
                  ? true
                  : false
              }
              buttonLabel="Pay Now"
              disabledButton={enabledButtonPay}
              debt_payed={debt_payed}
              extraProceed={setPayment}
              preventGoToCheckoutSuccess={true}
              currency={currency}
            >
              <div className="tw-flex">
                <div
                  className={`tw-flex-1 ${
                    hideBtnBook ? "" : "tw-border-r-[1px]"
                  }`}
                >
                  <div className="tw-flex tw-flex-col tw-font-bold">
                    <div className="tw-text-inspira-gray-100 tw-text-xl">
                      {initBookData?.subscription_code}
                    </div>
                    <div className="tw-text-black tw-text-2xl">
                      Total:{" "}
                      <span>
                        {`${getTotal(
                          initBookData?.charge_org ||
                            (initBookData?.plan_charge_after &&
                              initBookData?.plan_charge_after > 0)
                            ? 0
                            : (initBookData?.plan_amount || 0) +
                                (initBookData?.subscription_debt ?? 0),
                          cashCardApplied,
                          couponCodeApplied
                        )} ${currency}`}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </InspiraPg>
          </section>
        </div>
      )}
    </>
  );
}
