import { pathsEnabled } from "@data/videoHelp";
import { create } from "zustand";
import { useLayoutStore } from "./layoutStore";
export const VIDEO_PATH_DEFAULT =
  "https://d32ugu1nlarco2.cloudfront.net/videos/frontend/";

export const CONST_NORMAL = "normal";
export const CONST_DISCOUNT = "discount";
export const CONST_CERTIFICATE = "certificate";
export const CONST_UPGRADE = "upgrade";
interface helpVideoStoreType {
  showModal: boolean;
  videoCode: string;
  setShowModal: (val: boolean) => void;
  setVideoCode: (val: string) => void;
}
export const useHelpVideoStore = create<helpVideoStoreType>((set) => ({
  showModal: false,
  videoCode: "",

  setShowModal: async (val: boolean) => {
    set({ showModal: val });
  },
  setVideoCode: async (val: string) => {
    set({ videoCode: val, showModal: true });
  },
}));

export const showQuestionMarkMenu = (type: string) => {
  const { externalConfig } = useLayoutStore.getState();
  const isEnabledVideoHelp = externalConfig?.isEnabledVideoHelp;
  let currentPath =
    typeof window != "undefined" ? window?.location?.pathname : "";
  //remove home
  if (currentPath === "/home") {
    currentPath = "/";
  } else {
    currentPath = currentPath.replace("/home", "");
  }
  if (!isEnabledVideoHelp) {
    return null;
  }
  const findPath = pathsEnabled.find((p) => {
    return p.type == type && p.path == currentPath;
  });
  return findPath;
};

export const showQuestionMarkMenuV2 = (productPath: string) => {
  const { externalConfig } = useLayoutStore.getState();
  const isEnabledVideoHelp = externalConfig?.isEnabledVideoHelp;
  const currentPath = window?.location?.pathname;
  if (!isEnabledVideoHelp) {
    return null;
  }
  const findPath = pathsEnabled.find((p) => {
    return p.path == currentPath && p.path == productPath;
  });
  return findPath;
};

export const showQuestionMark = () => {
  const { externalConfig } = useLayoutStore.getState();
  const isEnabledVideoHelp = externalConfig?.isEnabledVideoHelp;
  const currentPath =
    typeof window != "undefined" ? window?.location?.pathname : "";
  if (!isEnabledVideoHelp) {
    return null;
  }
  const findPath = pathsEnabled.find((p) => {
    return p.path === currentPath;
  });
  return findPath;
};
