/* eslint-disable @next/next/no-img-element */
import { useAuth } from "@contexts/AuthContext";
import MenuItem from "./MenuItem";
import { MenuItemsV2 } from "@data/menuItems";
import { classNames } from "@helpers/helper";
import { useLayoutStore } from "@store/layoutStore";
import HeaderItem from "./HeaderItem";

type UserModalProps = {
  allItems: ItemMenu[];
  show: boolean;
};

export default function UserModal({ allItems, show }: UserModalProps) {
  const { setShowUserModal } = useLayoutStore();
  const { currentUser } = useAuth();

  const handleClose = () => {
    3;
    setShowUserModal(false);
  };
  return (
    <div
      className={classNames(
        "tw-fixed tw-inset-0 lg:tw-bottom-auto lg:tw-right-5 lg:tw-left-0 lg:tw-top-16 tw-z-50 tw-flex tw-justify-end lg:tw-px-0 tw-bg-inspira-bk-modal lg:tw-bg-transparent",
        show ? "tw-visible" : "tw-invisible"
      )}
      onClick={handleClose}
    >
      <div
        className={classNames(
          "tw-relative tw-flex tw-flex-col tw-h-auto tw-w-full tw-max-w-md lg:tw-w-[14rem]  tw-bg-white tw-rounded-l-md lg:tw-rounded-lg tw-shadow-4xl tw-transform tw-transition-transform tw-duration-500",
          show ? " tw-translate-x-0" : "tw-translate-x-full lg:tw-translate-x-0"
        )}
      >
        {/* Header with close button just mobile */}
        <div className="tw-flex lg:tw-hidden tw-justify-end tw-items-center tw-border-gray-200 tw-border-b-2 tw-py-2 tw-mx-2">
          <button
            onClick={handleClose}
            className="tw-text-brandcolorbutton hover:tw-text-brandcolorbutton tw-transition-colors tw-duration-200"
          >
            <img
              src="/images/general/close-bgWhite.svg"
              alt="close"
              className="tw-w-10 tw-h-8 tw-rounded-full"
            />
          </button>
        </div>
        {/*Content */}
        <div className="tw-flex tw-flex-col tw-h-auto tw-mx-2">
          <ul
            className={classNames(
              "tw-p-0 tw-text-gray-700 tw-w-full tw-flex tw-flex-col lg:tw-hidden tw-border-gray-200 tw-border-b-2 tw-px-2 tw-text-sm py-4"
            )}
          >
            {allItems.map((item, index) => {
              const currentKey = `menu-items-${index}`;
              return (
                <li key={currentKey}>
                  <HeaderItem item={item} onClick={() => handleClose()} />
                </li>
              );
            })}
          </ul>
          {MenuItemsV2?.sections?.map((menuGroup, index) => {
            const onlySigned = menuGroup?.onlySigned;
            const onlySignout = menuGroup?.onlySignout;

            if (onlySigned && !currentUser) {
              return null;
            }

            if (onlySignout && currentUser) {
              return null;
            }

            return (
              <div
                key={`menu-group-${index}`}
                className={classNames(
                  " tw-border-gray-200 tw-border-b-2 tw-w-full tw-flex tw-justify-center tw-items-center tw-flex-col tw-align-middle tw-text-left tw-text-sm tw-px-2 last:tw-border-none "
                )}
              >
                <label
                  className={classNames(
                    " tw-px-4 tw-text-left tw-w-full  pb-2"
                  )}
                >
                  {menuGroup.name}
                </label>
                <ul
                  className={classNames("tw-p-0 tw-text-gray-700 tw-w-full  ")}
                  aria-labelledby="dropdownDefaultButtonMenuNav"
                >
                  {menuGroup.items?.map((item, index2) => {
                    const currentKey = `menu-item-user-${index2}`;
                    return (
                      <li key={currentKey}>
                        <MenuItem item={item} onClick={() => handleClose()} />
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
