/* eslint-disable @next/next/no-img-element */
import ButtonItem from "./ButtomItem";
import { showQuestionMarkMenuV2 } from "@store/helpVideoStore";
import QuestionMarkV2 from "@components/QuestionMarkV2";

interface HeaderMenuItemsProps {
  items: ItemMenu[];
}

export default function HeaderMenuItems({ items }: HeaderMenuItemsProps) {
  return (
    <div className="tw-flex tw-flex-row  ">
      <ul className="tw-flex tw-flex-row tw-gap-2 tw-w-full ">
        {items.map((item, index) => {
          return (
            <li key={`header_menu_item_${index}`} className="tw-relative">
              <QuestionMarkV2
                codeVideo={showQuestionMarkMenuV2(item.url ?? "")?.codeVideo}
              >
                <ButtonItem item={item} />
              </QuestionMarkV2>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
