import { ModalSignIn } from "@components/Layout/Header/ModalSignIn";
import { ModalSignUp } from "@components/Layout/Header/ModalSignUp";
import codeResponse from "@data/codeResponseSignUp/codeResponse.json";
import { useMain } from "@contexts/mainContext";
import dynamic from "next/dynamic";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useBreakpoints } from "@hooks/useBreakpoints";
import { useLayoutStore } from "@store/layoutStore";

export default function AuthFlowWrapper({}) {
  const { orgConfig, showLoader } = useMain();

  const {
    showModalSignIn,
    showModalSignUp,
    showModalForgotPassword,
    setShowModalForgotPassword,
    setShowModalSignIn,
    setShowModalSignUp,
  } = useLayoutStore();
  const [isMobile, setIsMobile] = useState(false);
  const [showMailRegisterSignUpMode, setShowMailRegisterSignUpMode] =
    useState<any>(null);
  const [showSignUpOptions, setShowSignUpOptions] = useState(true);
  const [showMailRegister, setShowMailRegister] = useState(false);
  const [messageSignUp, setMessageSignUp] = useState("");
  const [bodyPassword, setBodyPassword] = useState(false);
  const [infoInvitation, setInfoInvitation] = useState<any>(null);

  const { breakpoints, width, height } = useBreakpoints();
  const router = useRouter();

  const handleOpenModalSignIn = () => {
    setShowModalSignIn(true);
  };

  const handleCloseModalSignIn = () => {
    setShowModalSignIn(false);
  };
  const handleOpenModalForgotPass = () => {
    setShowModalForgotPassword(true);
  };
  const handleOpenModalSignUp = () => {
    setShowModalSignUp(true);
  };

  const handleSetShowSignUpOptions = (state: boolean) => {
    setShowSignUpOptions(state);
  };

  const handelSetMessageSignUp = (codeMessage: string) => {
    const message = getMessageSignUp(codeMessage);
    setMessageSignUp(message);
  };
  const handleCloseModalSignUp = () => {
    setShowModalSignUp(false);
    setTimeout(() => {
      setShowMailRegister(false);
      setShowMailRegisterSignUpMode(false);
      setBodyPassword(false);
      setMessageSignUp("");
      handleSetShowSignUpOptions(true);
      handelSetMessageSignUp("");
    }, 500);
  };

  const getMessageSignUp = (codeMessage: string) => {
    let messageFound = codeMessage;
    codeResponse.data?.data?.map((obj: any) => {
      if (obj.code == codeMessage) {
        messageFound = obj.message;
      }
    });

    return messageFound;
  };
  const hanndleSetShowMailRegister = (shows: boolean) => {
    setShowMailRegister(shows);
  };

  const handdleShowMailRegisterSignUpMode = (setInfo: any) => {
    setShowMailRegisterSignUpMode(setInfo);
  };

  const handdleSetBodyPassword = (setMode: boolean) => {
    setBodyPassword(setMode);
    setMessageSignUp("");
  };
  const handleCloseModalForgotPass = () => {
    setShowModalForgotPassword(false);
  };

  const ModalForgotPassword = dynamic(
    () => import("@components/Layout/Header/ModalForgotPassword"),
    {
      ssr: false,
    }
  );

  useEffect(() => {
    if (router.query) {
      if (router?.query?.type == "email") {
        setShowMailRegister(true);
      }
      if (
        ((router?.query?.accesscode &&
          router?.query?.email &&
          router?.query?.firstname &&
          router?.query?.lastname &&
          router?.query?.title &&
          router?.query?.countrycode &&
          router?.query?.phonenumber &&
          router?.query?.lang &&
          router?.query?.tripid) ||
          router?.query?.signup == "true" ||
          router?.query?.register == "true") &&
        !showLoader
      ) {
        setInfoInvitation({
          mastercode: router?.query?.accesscode || "",
          email: router?.query?.email || "",
          lang: router?.query?.lang || "",
          tripid: router?.query?.tripid || "",
          lastname: router?.query?.lastname || "",
          firstname: router?.query?.firstname || "",
          title: router?.query?.title || "",
          countrycode: router?.query?.countrycode || "",
          phonenumber: router?.query?.phonenumber || "",
        });
      }
      if (
        ((router?.query?.accesscode &&
          router?.query?.email &&
          router?.query?.tripid) ||
          router?.query?.login == "true") &&
        !showLoader
      ) {
        setShowModalSignIn(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query, showLoader]);
  useEffect(() => {
    if (infoInvitation) {
      setShowModalSignUp(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoInvitation]);

  useEffect(() => {
    if (breakpoints.down("lg")) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
    /* IPAD NORMAL */
    if (width == 768 && height == 1024) {
      setIsMobile(true);
    } else if (width == 1024 && height == 768) {
      setIsMobile(true);
    }
  }, [breakpoints, width, height]);

  return (
    <>
      {showModalSignIn && (
        <ModalSignIn
          show={showModalSignIn}
          handleCloseModalSignIn={handleCloseModalSignIn}
          handleOpenModalForgotPass={handleOpenModalForgotPass}
          handleOpenModalSignUp={handleOpenModalSignUp}
          isMobile={isMobile}
        />
      )}
      {showModalSignUp && (
        <ModalSignUp
          show={showModalSignUp}
          handleCloseModalSignUp={handleCloseModalSignUp}
          handleOpenModalSignIn={handleOpenModalSignIn}
          isMobile={isMobile}
          showMailRegister={showMailRegister}
          hanndleSetShowMailRegister={hanndleSetShowMailRegister}
          showMailRegisterSignUpMode={showMailRegisterSignUpMode}
          handdleShowMailRegisterSignUpMode={handdleShowMailRegisterSignUpMode}
          bodyPassword={bodyPassword}
          handdleSetBodyPassword={handdleSetBodyPassword}
          accesscode={orgConfig?.orgMasterCode || ""}
          messageSignUp={messageSignUp}
          handelSetMessageSignUp={handelSetMessageSignUp}
          infoInvitation={infoInvitation}
          showSignUpOptions={showSignUpOptions}
          handleSetShowSignUpOptions={handleSetShowSignUpOptions}
        />
      )}

      {showModalForgotPassword && (
        <ModalForgotPassword
          show={showModalForgotPassword}
          handleCloseModalForgotPass={handleCloseModalForgotPass}
          handleOpenModalSignIn={handleOpenModalSignIn}
          isMobile={isMobile}
        />
      )}
    </>
  );
}
