import { getCurrencyCode } from "@helpers/helper";
import Image from "next/image";
import { useCommonCheckoutStore } from "@store/commonCheckoutStore";
import { useLayoutStore } from "@store/layoutStore";
import { shallow } from "zustand/shallow";
import { useCurrencyLangInfo } from "@hooks/useLayout";
import { IMAGE_PATH } from "@helpers/config";

export const LangIcon = () => {
  const { setShowCurrencyLanguage } = useCommonCheckoutStore();
  const { currencies } = useCurrencyLangInfo();

  const { isLoadedExternalConfig } = useLayoutStore(
    (state) => ({
      isLoadedExternalConfig: state.isLoadedExternalConfig,
    }),
    shallow
  );
  const getImgSelected = (currencyCode?: string | null) => {
    try {
      if (currencyCode) {
        const currency = currencies.find((c) => c.code === currencyCode);
        return IMAGE_PATH + currency?.flag || "";
      }
    } catch (error) {
      console.error(error);
    }

    return null;
  };

  if (!isLoadedExternalConfig) {
    return null;
  }

  return (
    <button
      onClick={() => setShowCurrencyLanguage(true)}
      className="tw-flex tw-items-center tw-relative tw-justify-center tw-gap-2 tw-cursor-pointer"
    >
      <div className="tw-w-8 tw-h-8">
        <Image
          src={getImgSelected(getCurrencyCode()) || ""}
          alt={getCurrencyCode()}
          width={30}
          height={30}
          className="object-cover tw-rounded-full tw-w-8 tw-h-8"
          onError={(e) => {
            e.currentTarget.src = "/images/flight/checkout/imgNotFound.png";
          }}
        />
      </div>
      <span className="tw-text-sm tw-font-medium tw-text-inspira-subhead-final span-currency-code">
        {getCurrencyCode()}
      </span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="8"
        height="4"
        viewBox="0 0 8 4"
        fill="none"
      >
        <path d="M0 0H7.44L3.69 3.87L0 0Z" fill="#A1A1A1" />
      </svg>
    </button>
  );
};
