/* eslint-disable @next/next/no-img-element */
import { useWhiteLabelLan } from "@hooks/useLayout";
import { classNames } from "@helpers/helper";
import { useAuth } from "@contexts/AuthContext";
import { useState } from "react";
import { useLayoutStore } from "@store/layoutStore";
import { shallow } from "zustand/shallow";
import UserModal from "./UserModal";

type UserMenuV2Props = {
  allItems: ItemMenu[];
};

export default function UserMenuV2({ allItems }: UserMenuV2Props) {
  const { getLangLabel } = useWhiteLabelLan();
  const { currentUser } = useAuth();
  const [userModal, setUserModal] = useState(false);

  const { showUserModal } = useLayoutStore(
    (state) => ({
      showUserModal: state.showUserModal,
    }),
    shallow
  );

  const { setShowUserModal } = useLayoutStore();

  const handleToggle = () => {
    setShowUserModal(!showUserModal);
    setUserModal(!userModal);
  };

  return (
    <div className="tw-z-50 tw-flex tw-text-centertw-justify-center tw-align-middle tw-items-center">
      <div className="tw-relative">
        <button
          id="dropdown_user_menu"
          data-dropdown-toggle="dropdown_nav_menu"
          className={classNames(
            " focus:tw-outline-none tw-font-medium  tw-text-sm tw-px-1 tw-py-1",
            "tw-text-center tw-inline-flex tw-items-center tw-justify-center tw-align-middle tw-gap-1",
            " tw-border-none md:tw-border md:tw-border-gray-400 tw-w-[40px] md:tw-min-w-[7rem] tw-min-w-[2rem] tw-gap-3",
            userModal ? "tw-text-[#112E77]" : "tw-text-inspira-accentcolor-txt"
          )}
          type="button"
          onClick={() => handleToggle()}
        >
          <div className="tw-flex tw-w-8 tw-h-8 ">
            <img
              src={"/images/header/signin-main-ico.svg"}
              alt="profile"
              className="tw-w-auto tw-h-auto "
            />
          </div>
          <span className="  tw-hidden md:tw-inline-flex tw-text-sm tw-font-medium ">
            {currentUser?.first_Name || getLangLabel("Menu")}
          </span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="4"
            viewBox="0 0 8 4"
            fill="none"
            className="tw-hidden lg:tw-block"
          >
            <path
              d="M0 0H7.44L3.69 3.87L0 0Z"
              fill={userModal ? "#112E77" : "#A1A1A1"}
            />
          </svg>
        </button>

        <UserModal allItems={allItems} show={showUserModal} />
      </div>
    </div>
  );
}
