type configVideosType = {
  path: string;
  codeVideo: string;
  type: string;
  captions?: string[];
  videoHelpTypes?: string[];
};
export const activities: configVideosType[] = [
  {
    path: "/home/activities",
    codeVideo: "activities_main",
    type: "activities",
  },
  {
    path: "/activities",
    codeVideo: "activities_main",
    type: "activities",
  },
  {
    path: "/activities/search-details",
    codeVideo: "activities_searchresults",
    type: "activities",
    videoHelpTypes: ["discount"],
  },
  {
    path: "/activities/details",
    codeVideo: "activities_activitiesdetails",
    type: "activities",
    videoHelpTypes: ["discount"],
  },
  {
    path: "/activities/checkout",
    codeVideo: "activities_checkout",
    type: "activities",
    videoHelpTypes: ["discount"],
  },
];

export const cars: configVideosType[] = [
  {
    path: "/home/cars",
    codeVideo: "cars_main",
    type: "cars",
  },
  {
    path: "/cars",
    codeVideo: "cars_main",
    type: "cars",
  },
  {
    path: "/cars/search-details",
    codeVideo: "cars_searchresults",
    type: "cars",
    videoHelpTypes: [],
  },
  {
    path: "/cars/details",
    codeVideo: "cars_carsdetails",
    type: "cars",
    videoHelpTypes: [],
  },
  {
    path: "/cars/checkout",
    codeVideo: "cars_checkout",
    type: "cars",
    videoHelpTypes: [],
  },
];
export const vacationRentals: configVideosType[] = [
  {
    path: "/home/holidaystays",
    codeVideo: "vacationrental_main",
    type: "vacationrental",
  },
  {
    path: "/holidaystays",
    codeVideo: "vacationrental_main",
    type: "vacationrental",
  },
  {
    path: "/holidaystays/search-detail",
    codeVideo: "vacationrental_searchresults",
    type: "vacationrental",
    videoHelpTypes: ["discount"],
  },
  {
    path: "/holidaystays/hotel-details",
    codeVideo: "vacationrental_rentaldetails",
    type: "vacationrental",
    videoHelpTypes: ["discount"],
  },
  {
    path: "/holidaystays/hotel-checkout",
    codeVideo: "vacationrental_checkout",
    type: "vacationrental",
    videoHelpTypes: ["discount"],
  },
];

export const hotels: configVideosType[] = [
  {
    path: "/home",
    codeVideo: "hotel_main",
    type: "hotel",
  },
  {
    path: "/",
    codeVideo: "hotel_main",
    type: "hotel",
  },
  {
    path: "/search-detail",
    codeVideo: "hotel_searchresults",
    type: "hotel",
    videoHelpTypes: ["discount", "upgrade"],
  },
  {
    path: "/hotel-details",
    codeVideo: "hotel_hoteldetails",
    type: "hotel",
    videoHelpTypes: ["discount"],
  },
  {
    path: "/hotel-checkout",
    codeVideo: "hotel_checkout",
    type: "hotel",
    videoHelpTypes: ["discount"],
  },
];

export const resorts: configVideosType[] = [
  {
    path: "/home/resorts",
    codeVideo: "resorts_main",
    type: "resorts",
  },
  {
    path: "/resorts",
    codeVideo: "resorts_main",
    type: "resorts",
  },
  {
    path: "/resorts/search-details",
    codeVideo: "resorts_searchresults",
    type: "resorts",
    videoHelpTypes: ["certificate", "upgrade"],
  },
  {
    path: "/resorts/details",
    codeVideo: "resorts_resortdetails",
    type: "resorts",
    videoHelpTypes: ["certificate"],
  },
  {
    path: "/resorts/checkout",
    codeVideo: "resorts_checkout",
    type: "resorts",
    videoHelpTypes: ["certificate"],
  },
];
export const stays: configVideosType[] = [
  {
    path: "/home/stays",
    codeVideo: "resorts_main",
    type: "stays",
  },
  {
    path: "/stays",
    codeVideo: "resorts_main",
    type: "stays",
  },
  {
    path: "/stays/search-details",
    codeVideo: "resorts_searchresults",
    type: "stays",
    videoHelpTypes: ["certificate", "upgrade"],
  },
  {
    path: "/stays/details",
    codeVideo: "resorts_resortdetails",
    type: "stays",
    videoHelpTypes: ["certificate"],
  },
  {
    path: "/stays/checkout",
    codeVideo: "resorts_checkout",
    type: "stays",
    videoHelpTypes: ["certificate"],
  },
];
export const cruises: configVideosType[] = [
  {
    path: "/home/cruises",
    codeVideo: "cruise_main",
    type: "cruises",
  },
  {
    path: "/cruises",
    codeVideo: "cruise_main",
    type: "cruises",
  },
];

export const flights: configVideosType[] = [
  {
    path: "/home/flight",
    codeVideo: "flights_main",
    type: "flight",
  },
  {
    path: "/flight",
    codeVideo: "flights_main",
    type: "flight",
  },
  {
    path: "/flight/search-details",
    codeVideo: "flights_searchresults",
    type: "flight",
    videoHelpTypes: [],
  },
  {
    path: "/flight/details", // ! revisar con ricardo
    codeVideo: "flights_flightdetails",
    type: "flight",
    videoHelpTypes: [],
  },
  {
    path: "/flight/checkout",
    codeVideo: "flights_checkout",
    type: "flight",
    videoHelpTypes: [],
  },
];
export const transfers: configVideosType[] = [
  {
    path: "/home/transfers",
    codeVideo: "transfers_main",
    type: "transfers",
  },
  {
    path: "/transfers/search-details",
    codeVideo: "transfers_searchresults",
    type: "transfers",
    videoHelpTypes: [],
  },
  {
    path: "/transfers/details",
    codeVideo: "transfers_transfersdetails",
    type: "transfers",
    videoHelpTypes: [],
  },
  {
    path: "/transfers/checkout",
    codeVideo: "transfers_checkout",
    type: "transfers",
    videoHelpTypes: [],
  },
];
export const pathsEnabled = hotels
  .concat(resorts)
  .concat(activities)
  .concat(cars)
  .concat(vacationRentals)
  .concat(flights)
  .concat(transfers)
  .concat(cruises)
  .concat(stays);
