import { create } from "zustand";

interface helpInstructionsStoreType {
  codePath: string;
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  setCodePath: (codePath: string) => void;
}

export const useHelpInstructionsStore = create<helpInstructionsStoreType>(
  (set) => ({
    codePath: "",
    showModal: false,
    setShowModal: (value: boolean) => set({ showModal: value }),
    setCodePath: (value: string) => set({ codePath: value }),
  })
);
