import { classNames } from "@helpers/helper";
import { useState } from "react";

interface TooltipProps {
  title: string;
  className?: string;
}
const Tooltip: React.FC<TooltipProps> = ({ title, className = "" }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  return (
    <div
      className={classNames(
        className,
        "tw-relative",
        "tw-w-auto tw-max-w-[100px]"
      )}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      <div className="tw-text-ellipsis tw-overflow-hidden tw-truncate tw-text-left">
        {title}
      </div>
      <div
        className={classNames(
          "tw-hidden tw-z-[8] tw-transition-all tw-duration-300 tw-ease-in-out",
          "tw-left-0 tw-top-6 tw-bg-inspira-purple-100 tw-p-2 tw-rounded tw-text-white tw-text-xs tw-absolute tw-z-10 ",
          {
            "!tw-block": showTooltip,
          }
        )}
      >
        <div className="tw-w-full tw-relative tw-z-10">
          <div className="tw-absolute tw-bg-inspira-purple-100 tw-top-[-10px] tw-z-[9] tw-w-[15px] tw-h-[15px] tw-rotate-45"></div>
        </div>
        <span className="tw-z-[11] tw-relative tw-h-full tw-w-full tw-text-base font-semibold">
          {title}
        </span>
      </div>
    </div>
  );
};

export default Tooltip;
