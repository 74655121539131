import { getCurrencySimbol } from "@lib/activities/pricesManagement";

export const certificateNameAvailable = (
  certificates: Wallet_Personal_API_Certificate_Data_Type[],
  currentCertificateSelected: string,
  productCode: string
) => {
  let certificateName = "";
  const first = certificates?.find((f) => f);
  const countCerts = certificates.length;
  certificateName = `${countCerts} ${first?.payment_card_name}`;

  if (currentCertificateSelected) {
    const exist = certificates.find((f) => {
      const idCalc = `${f.card_code}-${f.payment_card_id}`;
      return (
        idCalc == currentCertificateSelected &&
        f.pro_code.toUpperCase().includes(productCode.toUpperCase())
      );
    });
    if (exist) {
      return `${countCerts} ${exist.payment_card_name}`;
    }
    return certificateName;
  }
  return certificateName;
};

export const discountCardNameAvailable = (
  discounts: Wallet_Personal_API_DiscountCard_Data_Type[],
  currentDiscountCardSelected: string,
  productCode: string
) => {
  let discountCardName = "";
  const first = discounts?.find((f) => f);
  discountCardName = `${getCurrencySimbol(first?.currency_code)}${Math.round(
    Number(first?.card_amount || 0)
  )} ${first?.payment_card_name}`;
  if (currentDiscountCardSelected) {
    const exist = discounts.find((f) => {
      const idCalc = `${f.card_code}-${f.payment_card_id}`;
      return (
        idCalc == currentDiscountCardSelected &&
        f.pro_code.toUpperCase().includes(productCode.toUpperCase())
      );
    });
    if (exist) {
      return `${getCurrencySimbol(exist.currency_code)}${Math.round(
        Number(exist.card_amount || 0)
      )} ${exist.payment_card_name}`;
    }
    return discountCardName;
  }
  return discountCardName;
};
