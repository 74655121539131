/* eslint-disable @next/next/no-img-element */
import Tooltip from "../HeaderV2/HeaderMenuContent/MenuUser/tooltip";
import { usePersonalWallet } from "@store/WalletPersonalStore";
import { getCurrencySimbol } from "@lib/activities/pricesManagement";
import { shallow } from "zustand/shallow";
import { useRouter } from "next/router";
import { useAuth } from "@contexts/AuthContext";
import { classNames } from "@helpers/helper";
import { useWhiteLabelLan } from "@hooks/useLayout";
import { getCurrencyByLanguage } from "@helpers/helperFormatCurrency";
import {
  certificateNameAvailable,
  discountCardNameAvailable,
} from "@helpers/helperLayout";

import { PRODUCT_OPTIONS_HOTEL, useWalletStore } from "@store/walletUserStore";

export const Wallet = () => {
  const { currentDiscountCardSelected, currentCertificateSelected } =
    useWalletStore(
      (state) => ({
        currentCertificateSelected: state.currentCertificateSelected,
        currentDiscountCardSelected: state.currentDiscountCardSelected,
      }),
      shallow
    );
  const { isLogged } = useAuth();
  const router = useRouter();
  const { getLangLabel } = useWhiteLabelLan();

  const {
    personalWalletInfo,
    walletPersonalSelected,
    currentOptionSelected,
    globalCashCard,
    globalCashCardCurrency,
  } = usePersonalWallet();
  const personalWalletInfoDiscountArray: boolean =
    personalWalletInfo?.discountCardData?.length > 0;
  const personalWalletInfoCertificateArray: boolean =
    personalWalletInfo?.certificateData?.length > 0;

  const getCertificateCount = (
    personalWalletInfo: PersonalWalletInfo,
    paymentName: string
  ) => {
    let certificateData: number = 0;
    personalWalletInfo?.certificateData?.map(
      (obj: Wallet_Personal_API_Certificate_Data_Type) => {
        if (obj?.payment_card_name == paymentName) {
          certificateData++;
        }
      }
    );
    return certificateData;
  };

  const getFirstDiscountCard = (
    personalWalletInfo: PersonalWalletInfo,
    walletPersonalSelected: Wallet_Personal_Current_Selected_Info | null,
    currentOptionSelected: Wallet_Personal_Current_Selected_Type
  ) => {
    let discountCardData: string = "";
    if (personalWalletInfo.product_type == PRODUCT_OPTIONS_HOTEL.code) {
      personalWalletInfo?.discountCardData?.map(
        (obj: Wallet_Personal_API_DiscountCard_Data_Type, index: number) => {
          if (
            currentOptionSelected == "discount_cards" &&
            walletPersonalSelected &&
            walletPersonalSelected?.card_code == obj?.card_code
          ) {
            discountCardData = `${getCurrencySimbol(
              obj?.currency_code
            )}${Math.round(Number(obj?.card_amount || 0))} ${
              obj?.payment_card_name
            }`;
          } else if (index == 0) {
            discountCardData = `${getCurrencySimbol(
              obj?.currency_code
            )}${Math.round(Number(obj?.card_amount || 0))} ${
              obj?.payment_card_name
            }`;
          }
        }
      );
      return discountCardData;
    }
    return discountCardNameAvailable(
      personalWalletInfo.discountCardData,
      currentDiscountCardSelected,
      personalWalletInfo.product_type
    );
  };

  const getFirstCertificate = (
    personalWalletInfo: PersonalWalletInfo,
    walletPersonalSelected: Wallet_Personal_Current_Selected_Info | null,
    currentOptionSelected: Wallet_Personal_Current_Selected_Type
  ) => {
    let certificateData: string = "";
    if (personalWalletInfo.product_type == PRODUCT_OPTIONS_HOTEL.code) {
      personalWalletInfo?.certificateData?.map(
        (obj: Wallet_Personal_API_Certificate_Data_Type, index: number) => {
          if (
            currentOptionSelected == "certificates" &&
            walletPersonalSelected
          ) {
            certificateData = `${getCertificateCount(
              personalWalletInfo,
              walletPersonalSelected?.payment_card_name
            )} ${walletPersonalSelected?.payment_card_name}`;
          } else if (index == 0) {
            certificateData = `${getCertificateCount(
              personalWalletInfo,
              obj?.payment_card_name
            )} ${obj?.payment_card_name}`;
          }
        }
      );
      return certificateData;
    }
    return certificateNameAvailable(
      personalWalletInfo.certificateData,
      currentCertificateSelected,
      personalWalletInfo.product_type
    );
  };

  const discountCardWalletInfo: string = personalWalletInfoDiscountArray
    ? `${getFirstDiscountCard(
        personalWalletInfo,
        walletPersonalSelected,
        currentOptionSelected
      )}`
    : ``;

  const certificateWalletInfo: string = personalWalletInfoCertificateArray
    ? `${getFirstCertificate(
        personalWalletInfo,
        walletPersonalSelected,
        currentOptionSelected
      )}`
    : ``;
  return (
    <li
      className={classNames(
        !isLogged ? "!tw-hidden" : "",
        "tw-hidden lg:tw-flex tw-items-center tw-capitalize tw-text-orange-500 tw-gap-2 tw-justify-between tw-align-middle tw-cursor-pointer"
      )}
      onClick={() => {
        router.push("/wallet");
      }}
    >
      <div className="tw-w-7 tw-h-7">
        <img
          className="lg:tw-w-[28px] tw-h-7 tw-w-7 wallet-image2"
          alt="wallet"
          src={"/images/userLogged/WalletOrange.svg"}
        />
      </div>
      <span className="txt-img-wallet tw-capitalize tw-hidden lg:tw-flex">
        {String(
          getLangLabel("WALLET", { showEmpty: true }) || ""
        ).toLowerCase()}
      </span>
      <div
        className={classNames(
          "tw-flex tw-flex-col tw-justify-center tw-items-start tw-align-middle  tw-h-full tw-group"
        )}
      >
        <Tooltip
          className={classNames(
            "text-wallet",
            globalCashCard > 0 ? "" : "tw-hidden"
          )}
          title={
            globalCashCard > 0
              ? `${getCurrencySimbol(
                  globalCashCardCurrency
                )}${getCurrencyByLanguage(globalCashCard, {
                  simbol: false,
                })}`
              : ""
          }
        />
        <Tooltip
          className={classNames(
            personalWalletInfoDiscountArray ? "" : "tw-hidden"
          )}
          title={discountCardWalletInfo ? discountCardWalletInfo : ""}
        />
        <Tooltip
          className={classNames(
            personalWalletInfoCertificateArray ? "" : "tw-hidden"
          )}
          title={certificateWalletInfo ? certificateWalletInfo : ""}
        />
      </div>
    </li>
  );
};
