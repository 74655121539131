import { TIMER, RESORTS_TIMER } from "@helpers/config";
import { convertMsToTime } from "@helpers/helper";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import useCountDown from "react-countdown-hook";
import useIsMountedRef from "./useIsMountedRef";

let initialTime = TIMER * 60 * 1000; // initial time in milliseconds, defaults to 60000
const interval = 1000;
const pagesActivate = [
  "/hotel-checkout",
  "/cars/checkout",
  "/flight/checkout",
  "/resorts/checkout",
  "/stays/checkout",
];
const weeksRoutes = ["/resorts/checkout", "/stays/checkout"];
export const useCounter = () => {
  const router = useRouter();
  const isMountedRef = useIsMountedRef();

  if (
    typeof window != "undefined" &&
    weeksRoutes.includes(window.location.pathname)
  ) {
    initialTime = RESORTS_TIMER * 60 * 1000;
  }

  const [showClock, setShowClock] = useState(false);
  const [timeLeft, { start, pause, reset }] = useCountDown(
    initialTime,
    interval
  );

  useEffect(() => {
    if (isMountedRef) {
      if (pagesActivate.includes(window.location.pathname)) {
        setShowClock(true);
        start();
      } else {
        reset();
        pause();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start, isMountedRef]);
  useEffect(() => {
    if (isMountedRef) {
      if (
        timeLeft === 0 &&
        pagesActivate.includes(window.location.pathname) &&
        showClock
      ) {
        if (process.env.NEXT_PUBLIC_TIMEOUT_CHECKOUT_DISABLED !== "true") {
          reset();
          router.push({
            pathname: "/",
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft, isMountedRef]);

  return convertMsToTime(timeLeft);
};
