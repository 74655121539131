import React, { useEffect, useState } from "react";
import { useWhiteLabelLan } from "@hooks/useLayout";
import { classNames } from "@helpers/helper";
import { showQuestionMarkMenuV2 } from "@store/helpVideoStore";
import HeaderItem from "./HeaderItem";
import QuestionMarkV2 from "@components/QuestionMarkV2";

interface ButtonMenuItemsProps {
  items: ItemMenu[];
}

export default function ButtonMenuItems({ items }: ButtonMenuItemsProps) {
  const [showMenu, setShowMenu] = useState(false);
  const ref = React.useRef<any>(null);
  const { getLangLabel } = useWhiteLabelLan();

  const handleToggle = () => {
    setShowMenu(!showMenu);
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref?.current && !ref?.current?.contains(event.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <div
      ref={ref}
      className="tw-flex tw-text-center tw-pr-4 tw-justify-center tw-align-middle tw-items-center"
    >
      <div className="tw-relative">
        <button
          id="ButtonMenuHeaderNav"
          type="button"
          data-dropdown-toggle="dropdown_nav_menu"
          className={classNames(
            "hover:tw-bg-[#112E77] hover:tw-text-white tw-text-inspira-accentcolor-txt focus:tw-outline-none hover:tw-border-none focus:tw-ring-0 tw-font-medium tw-rounded-full tw-text-sm tw-px-1 tw-py-1 tw-text-center tw-inline-flex tw-items-center tw-justify-center tw-align-middle  md:tw-border-2 md:tw-border-inspira-accentcolor-txt tw-w-[40px] md:tw-min-w-[7rem] ",
            showMenu
              ? "tw-bg-[#112E77] tw-text-white tw-border-none"
              : "tw-text-gray-700 tw-border-gray-400"
          )}
          onClick={() => {
            handleToggle();
          }}
        >
          <span className="tw-hidden md:tw-inline-flex tw-text-sm tw-font-medium">
            {getLangLabel("More Travel")}
          </span>
        </button>
        <div
          id="dropdownMenuHeaderNav"
          className={classNames(
            "tw-absolute tw-z-40 tw-border tw-bg-white tw-rounded-3xl tw-shadow-2xl",
            showMenu ? "" : "tw-hidden",
            "tw-absolute -tw-right-[2rem] tw-top-10 tw-mx-auto tw-w-[15rem] lg:tw-w-[14rem] tw-will-change-auto tw-transition-all tw-duration-300 tw-ease-in-out",
            "tw-flex tw-justify-center tw-items-center tw-flex-col tw-align-middle tw-py-5 tw-px-1"
          )}
        >
          {items.map((item, index) => (
            <li
              key={`menu-item-${index}`}
              className={classNames(
                "tw-w-full tw-flex tw-relative  tw-flex-col tw-text-center tw-text-[12.5px] tw-justify-center tw-items-center tw-align-middle tw-px-2 ",
                index > 0 ? "tw-pt-1" : ""
              )}
            >
              <QuestionMarkV2
                codeVideo={showQuestionMarkMenuV2(item.url ?? "")?.codeVideo}
                isMenu={true}
              >
                <HeaderItem item={item} />
              </QuestionMarkV2>
            </li>
          ))}
        </div>
      </div>
    </div>
  );
}
