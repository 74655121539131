import currencyList from "@data/CurrencyList.json";
export const getCurrencySimbol = (currency: string | undefined) => {
  const code = currency ? currency : "USD";
  const symb = currencyList.filter((entry) =>
    Object.values(entry).some(
      (val) => typeof val === "string" && val.match(code)
    )
  );
  return symb.length > 0 ? symb[0].symbol : "$";
};
/* SEARCH - DETAIL PAGE */
export const initMarginStrikePrice = (
  isLogged: boolean,
  planMemberShipStatus: string,
  price: PriceActivitieType
) => {
  const strikePriceMarkup: boolean = showStrikePriceByType(
    isLogged,
    planMemberShipStatus,
    getStrikePrice(price),
    price,
    "strikeMarkup"
  );
  const strikePriceB2CMarkup: boolean = showStrikePriceByType(
    isLogged,
    planMemberShipStatus,
    getStrikePrice(price),
    price,
    "b2cMarkup"
  );
  const finalDesicion: boolean =
    strikePriceMarkup && strikePriceB2CMarkup ? true : false;

  return finalDesicion;
};
export const showStrikePriceByType = (
  isLogged: boolean,
  planMemberShipStatus: string,
  strikePrice: number,
  price: PriceActivitieType,
  type: "strikeMarkup" | "b2cMarkup"
) => {
  let show: boolean = false;
  const strikePriceMarkup: number =
    type == "strikeMarkup"
      ? strikePrice
      : type == "b2cMarkup"
      ? price?.b2cMarkup
      : 0;

  if (isLogged && planMemberShipStatus == "ACTIVE" && strikePriceMarkup > 0) {
    show = true;
  }
  return show;
};
export const getStrikePriceByType = (
  price: PriceActivitieType,
  strikePrice: number,
  type: "strikeMarkup" | "b2cMarkup"
) => {
  return type == "strikeMarkup"
    ? strikePrice
    : type == "b2cMarkup"
    ? price?.b2cMarkup
    : 0;
};
export const showStrikePrice = (
  isLogged: boolean,
  planMemberShipStatus: string,
  strikePrice: number,
  price: PriceActivitieType
) => {
  let show: boolean = false;
  if (isLogged && planMemberShipStatus == "ACTIVE") {
    if (strikePrice > 0) {
      show = true;
    } else if (strikePrice == 0 && price?.b2cMarkup > 0) {
      show = true;
    }
  }
  return show;
};

export const getStrikePrice = (price: PriceActivitieType) => {
  let strikePrice: number = 0;
  strikePrice = price?.strikeMarkup || 0;
  /* if (!strikePrice) {
    strikePrice = price.b2cMarkup;
  } */
  return strikePrice;
};

export const getPriceActivitie = (
  price: PriceActivitieType,
  isLogged: boolean,
  planMemberShipStatus: string
) => {
  let finalPrice: number = 0;
  if (isLogged && planMemberShipStatus == "ACTIVE") {
    const tmpB2B: any =
      price?.b2bMarkup % 1 > 0
        ? price?.b2bMarkup.toFixed(2)
        : price?.b2bMarkup.toFixed(0);
    finalPrice = Number(tmpB2B || 0);
  } else {
    const tmpB2C: any =
      price?.b2cMarkup % 1 > 0
        ? price?.b2cMarkup.toFixed(2)
        : price?.b2cMarkup.toFixed(0);
    finalPrice = Number(tmpB2C || 0);
  }
  return finalPrice;
};

export const getB2bActivitie = (
  price: PriceActivitieType,
  isLogged: boolean,
  planMemberShipStatus: string
) => {
  let b2b: number = 0;
  if (isLogged && planMemberShipStatus == "ACTIVE") {
    const tmpB2B: any =
      price?.b2bMarkup % 1 > 0 ? price?.b2b.toFixed(2) : price?.b2b.toFixed(0);
    b2b = Number(tmpB2B || 0);
  } else {
    const tmpB2C: any =
      price?.b2cMarkup % 1 > 0 ? price?.b2c.toFixed(2) : price?.b2c.toFixed(0);
    b2b = Number(tmpB2C || 0);
  }
  return b2b;
};

/* INFO PRICE OVERLAY */

export const getActivitiePriceInfoPriceOverlay = (
  planMemberShipStatus: string,
  price: PriceActivitieType,
  isLogged: boolean
) => {
  let finalPrice: number = 0;
  let tmpPrice: any = 0;
  if (isLogged && planMemberShipStatus == "ACTIVE") {
    tmpPrice =
      price?.b2bMarkup % 1 > 0
        ? price?.b2bMarkup.toFixed(2)
        : price?.b2bMarkup.toFixed(0);
  } else {
    tmpPrice =
      price?.b2cMarkup % 1 > 0
        ? price?.b2cMarkup.toFixed(2)
        : price?.b2cMarkup.toFixed(0);
  }
  finalPrice = Number(tmpPrice);
  return finalPrice;
};

export const getTaxesPriceInfoPriceOverlay = (
  planMemberShipStatus: string,
  price: PriceActivitieType,
  isLogged: boolean
) => {
  let finalTaxes: number = 0;
  let tmpTax: any = 0;
  if (isLogged && planMemberShipStatus == "ACTIVE") {
    tmpTax =
      price?.b2bTaxes % 1 > 0
        ? price?.b2bTaxes.toFixed(2)
        : price?.b2bTaxes.toFixed(0);
  } else {
    tmpTax =
      price?.b2cTaxes % 1 > 0
        ? price?.b2cTaxes.toFixed(2)
        : price?.b2cTaxes.toFixed(0);
  }
  finalTaxes = Number(tmpTax);
  return finalTaxes;
};

export const getTotalPriceInfoPriceOverlay = (
  planMemberShipStatus: string,
  price: PriceActivitieType,
  isLogged: boolean
) => {
  let totalPriceFinal: number = 0;
  let tmpTotalPriceFinal1: number = 0;
  let tmpTotalPriceFinal2: string = "";
  const newPrice: number = getActivitiePriceInfoPriceOverlay(
    planMemberShipStatus,
    price,
    isLogged
  );
  const taxes: number = getTaxesPriceInfoPriceOverlay(
    planMemberShipStatus,
    price,
    isLogged
  );
  tmpTotalPriceFinal1 = newPrice + taxes;
  tmpTotalPriceFinal2 =
    tmpTotalPriceFinal1 % 1 > 0
      ? tmpTotalPriceFinal1.toFixed(2)
      : tmpTotalPriceFinal1.toFixed(0);
  totalPriceFinal = Number(tmpTotalPriceFinal2);
  return totalPriceFinal;
};

/* DETAIL PAGE */

export const getPricePerPerson = (
  price: PriceActivitieType,
  isLogged: boolean,
  planMemberShipStatus: string,
  numberOfTravelers: number
) => {
  let finalPrice: number = 0;
  const pricePerPerson: number =
    isLogged && planMemberShipStatus == "ACTIVE"
      ? price?.b2bMarkup / numberOfTravelers
      : price?.b2cMarkup / numberOfTravelers;
  const tmpPrice: any =
    pricePerPerson % 1 > 0
      ? pricePerPerson.toFixed(2)
      : pricePerPerson.toFixed(0);
  finalPrice = Number(tmpPrice || 0);
  return finalPrice;
};

export const getPricePerTypeTraveler = (
  price: PriceActivitieType,
  isLogged: boolean,
  planMemberShipStatus: string
) => {
  let finalPrice: number = 0;
  const pricePerPerson: number =
    isLogged && planMemberShipStatus == "ACTIVE"
      ? price?.b2bMarkup
      : price?.b2cMarkup;
  const tmpPrice: any =
    pricePerPerson % 1 > 0
      ? pricePerPerson.toFixed(2)
      : pricePerPerson.toFixed(0);
  finalPrice = Number(tmpPrice || 0);
  return finalPrice;
};

/* CHECK-OUT PAGE */

export const getSubTotalPrice = (
  price: PriceActivitieType,
  isLogged: boolean,
  planMemberShipStatus: string
) => {
  let subTotal: number = 0;
  if (isLogged && planMemberShipStatus == "ACTIVE") {
    const tmpB2B: any =
      price?.b2bMarkup % 1 > 0
        ? price?.b2bMarkup.toFixed(2)
        : price?.b2bMarkup.toFixed(0);
    subTotal = Number(tmpB2B || 0);
  } else {
    const tmpB2C: any =
      price?.b2cMarkup % 1 > 0
        ? price?.b2cMarkup.toFixed(2)
        : price?.b2cMarkup.toFixed(0);
    subTotal = Number(tmpB2C || 0);
  }
  return subTotal;
};
export const getTotalPrice = (
  price: PriceActivitieType,
  isLogged: boolean,
  planMemberShipStatus: string
) => {
  let total: number = 0;
  if (isLogged && planMemberShipStatus == "ACTIVE") {
    const tmpB2B: any =
      price?.b2bMarkup % 1 > 0
        ? price?.b2bMarkup.toFixed(2)
        : price?.b2bMarkup.toFixed(0);
    total = Number(tmpB2B || 0);
  } else {
    const tmpB2C: any =
      price?.b2cMarkup % 1 > 0
        ? price?.b2cMarkup.toFixed(2)
        : price?.b2cMarkup.toFixed(0);
    total = Number(tmpB2C || 0);
  }
  return total;
};
export const getAllDiscountCardData = (
  discountCardData: discount_card_data_type[]
) => {
  return discountCardData;
};
