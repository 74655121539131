/* eslint-disable @next/next/no-img-element */
import Link from "next/link";
import { classNames } from "@helpers/helper";
import { useMain } from "@contexts/mainContext";
import { useWhiteLabelLan } from "@hooks/useLayout";
import { useMenuItemsAction } from "@hooks/useMenuItemsAction";
import { useAuth } from "@contexts/AuthContext";
import { getCookie } from "cookies-next";
import { useState } from "react";

export default function ButtonItem({
  item,
  onClick,
}: {
  item: ItemMenu;
  onClick?: () => void;
}) {
  const { getLangLabel } = useWhiteLabelLan();
  const { isLogged } = useAuth();
  const { getAction, removeOrgIdInitbookCookie } = useMenuItemsAction(); //revisar
  const { profileTabSelected } = useMain();
  const [isHovered, setIsHovered] = useState(false);
  let lanCode = String(
    getCookie("lan_code") ? getCookie("lan_code") : "default"
  );
  const isCurrentKeySelected = (key: string) => {
    // check current key is the same in the path name
    if (typeof window !== "undefined") {
      const pathName = window.location.pathname; // /home /home/cars /home/hotels etc

      if (pathName == "/user-details") {
        const option_id = String(item?.id || "");
        const isSelected: boolean = option_id == profileTabSelected;
        return isSelected;
      } else if (pathName == key) {
        return true;
      } else if (pathName.includes("home")) {
        // /home /home/cars /home/hotels etc
        const pathNameRelative = pathName.replace("/home", "");
        return (
          pathNameRelative == key || pathNameRelative == key.replace("/", "")
        );
      }
    }
    return false;
  };

  const getItembyLang = (
    item: string | { [key: string]: string },
    codeLang: string
  ) => {
    if (typeof item === "object") {
      return item[codeLang] || item["default"];
    }
    return item;
  };

  if (item.onlySigned && !isLogged) {
    return null;
  }

  if (item.onlySignout && isLogged) {
    return null;
  }

  if (item.action) {
    return (
      <button
        onClick={() => {
          getAction(item.action || "", item);
          onClick && onClick();
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className={classNames(
          "tw-px-4 tw-no-underline tw-text-inspira-accentcolor-txt hover:tw-text-[#112E77] tw-flex tw-flex-col tw-gap-1 tw-my-1 tw-py-1 tw-items-center tw-justify-center tw-max-w-[70px] tw-w-full"
        )}
      >
        {item?.images && (
          <div className="tw-relative tw-w-6 tw-h-6">
            <img
              src={item.images.on}
              alt="menu"
              className={classNames(
                "tw-absolute tw-inset-0 tw-w-full tw-h-full tw-object-contain",
                isCurrentKeySelected(item?.url || "#") || isHovered
                  ? ""
                  : "tw-hidden"
              )}
            />
            <img
              src={item.images.off}
              alt="menu"
              className={classNames(
                "tw-absolute tw-inset-0 tw-w-full tw-h-full tw-object-contain",
                isCurrentKeySelected(item?.url || "#") || isHovered
                  ? "tw-hidden"
                  : ""
              )}
            />
          </div>
        )}
        <span
          className={classNames(
            "tw-text-center tw-text-sm ",
            isCurrentKeySelected(item?.url || "#") ? "tw-text-[#112E77] " : ""
          )}
        >
          {getLangLabel(getItembyLang(item.label, lanCode))}
        </span>
      </button>
    );
  }

  return (
    <Link href={item.url || "#"}>
      <a
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className={classNames(
          "tw-px-4 tw-no-underline tw-text-inspira-accentcolor-txt hover:tw-text-[#112E77] tw-flex tw-flex-col tw-gap-1 tw-my-1 tw-py-1 tw-items-center tw-justify-center tw-max-w-[70px] tw-w-full"
        )}
        onClick={() => {
          onClick && onClick();
          removeOrgIdInitbookCookie();
        }}
      >
        {item?.images && (
          <div className="tw-relative tw-w-6 tw-h-6">
            <img
              src={item.images.on}
              alt="menu"
              className={classNames(
                "tw-absolute tw-inset-0 tw-w-full tw-h-full tw-object-contain",
                isCurrentKeySelected(item?.url || "#") || isHovered
                  ? ""
                  : "tw-hidden"
              )}
            />
            <img
              src={item.images.off}
              alt="menu"
              className={classNames(
                "tw-absolute tw-inset-0 tw-w-full tw-h-full tw-object-contain",
                isCurrentKeySelected(item?.url || "#") || isHovered
                  ? "tw-hidden"
                  : ""
              )}
            />
          </div>
        )}
        <span
          className={classNames(
            "tw-text-center tw-text-sm ",
            isCurrentKeySelected(item?.url || "#") ? "tw-text-[#112E77] " : ""
          )}
        >
          {getLangLabel(getItembyLang(item.label, lanCode))}
        </span>
      </a>
    </Link>
  );
}
