/* eslint-disable @next/next/no-img-element */
import { getOrgConfig, IMAGE_PATH, LOGO_URL } from "@helpers/config";
import { useLayoutStore } from "@store/layoutStore";
import { checkCookies, getCookie } from "cookies-next";
import { useCounter } from "@hooks/useCounter";
import Link from "next/link";
import Image from "next/image";
import { shallow } from "zustand/shallow";
import { classNames } from "@helpers/helper";
import { useWhiteLabelLan } from "@hooks/useLayout";
import HeaderMenuItems from "./HeaderMenuItems";
import ButtonMenuItems from "./ButtonMenuItems";
import UserMenuV2 from "./UserMenuV2";
import { LangIcon } from "../HeaderV2/HeaderMenuContent/MenuUser/LangIcon";
import { Wallet } from "./Wallet";
import { showQuestionMark } from "@store/helpVideoStore";
import QuestionMarkV2 from "@components/QuestionMarkV2";

type NavbarProps = {
  showUserMenu?: boolean;
  sticky?: boolean;
};
export default function NavbarV3({
  showUserMenu = true,
  sticky = false,
}: NavbarProps) {
  const { getLangLabel } = useWhiteLabelLan();
  const { logoUrl, upgradeUrl, isLoadedExternalConfig, showUserModal } =
    useLayoutStore(
      (state) => ({
        logoUrl: state.externalConfig?.logoUrl || "",
        upgradeUrl: state.externalConfig?.upgradeOptions?.url || "",
        isLoadedExternalConfig: state.isLoadedExternalConfig,
        showUserModal: state.showUserModal,
      }),
      shallow
    );
  const { setShowUserModal } = useLayoutStore();
  const countDown = useCounter();
  const showQuestionVideoHelp = showQuestionMark();

  const getLogoUrl = (externalImage: string) => {
    if (externalImage) {
      return externalImage;
    }
    if (LOGO_URL) return LOGO_URL;
    if (getCookie("logo")) return `${IMAGE_PATH}${getCookie("logo")}`;
    return "";
  };

  if (!isLoadedExternalConfig) {
    return null;
  }

  const logo = getLogoUrl(logoUrl);
  const items = getOrgConfig(
    String(checkCookies("orgid") ? getCookie("orgid") : "")
  );

  const itemsHeader = items?.filter((p) => p?.section == "header");
  const itemsMenu = items?.filter((p) => p?.section == "menu");
  const itemsHeaderAndMenu = items?.filter(
    (p) => p?.section == "header" || p?.section == "menu"
  );

  const handleToggle = () => {
    setShowUserModal(!showUserModal);
  };

  return (
    <section>
      <nav
        className={classNames(
          "tw-flex tw-flex-row tw-justify-between tw-h-20 ",
          sticky ? "tw-sticky tw-top-0 tw-z-50" : ""
        )}
      >
        <div className="tw-container tw-max-w-[300px] tw-min-w-[170px] tw-justify-center tw-items-center tw-align-middle tw-flex tw-flex-row tw-gap-2 lg:tw-gap-10 tw-px-4">
          <Link href="/">
            <a>
              {logo && (
                <img
                  className="  tw-h-full tw-w-full tw-max-h-20 logo2 img-fluid tw-py-[5px] tw-px-[10px]"
                  src={logo}
                  alt="logo"
                />
              )}
            </a>
          </Link>
        </div>
        <div className=" lg:tw-flex  tw-hidden tw-justify-center tw-w-full tw-flex-row tw-gap-2 lg:tw-gap-10  ">
          <HeaderMenuItems items={itemsHeader || []} />
          {itemsMenu.length > 0 && <ButtonMenuItems items={itemsMenu} />}
          {/* {<Menu />} */}
        </div>
        <div className=" tw-flex tw-flex-row tw-gap-2 lg:tw-gap-10 tw-px-4">
          <div
            className={classNames(
              "  tw-hidden md:tw-flex tw-justify-center tw-items-center tw-align-middle",
              upgradeUrl ? "" : "!tw-hidden"
            )}
          >
            <Link href={upgradeUrl}>
              <a
                className={classNames(
                  "tw-hidden md:tw-inline-flex",
                  "hover:tw-bg-inspira-purple-100 tw-bg-inspira-purple-600 tw-text-white tw-min-w-[7rem] tw-border tw-border-gray-400",
                  "focus:tw-ring-4 focus:tw-outline-none focus:tw-ring-blue-300 tw-font-medium tw-rounded-full tw-text-sm tw-px-1 tw-py-1",
                  "tw-text-center  tw-items-center tw-justify-center tw-align-middle tw-no-underline"
                )}
              >
                {getLangLabel("Upgrade Now")}
              </a>
            </Link>
          </div>
          {showUserMenu && (
            <div className=" tw-flex tw-justify-between tw-items-center tw-align-middle lg:tw-gap-4 tw-gap-1 tw-border-l-2 lg:tw-px-4 tw-pl-4">
              <Wallet />
              <LangIcon />
              <UserMenuV2 allItems={itemsHeaderAndMenu} />
              {countDown !== "00:00" && (
                <>
                  <span className="tw-border tw-border-orange-600 lg:tw-hidden tw-text-orange-600 tw-h-10 tw-inline-block tw-self-center tw-align-middle tw-ml-1" />
                  <div className="tw-flex-col tw-items-center  lg:tw-justify-between  tw-flex lg:tw-flex-row lg:tw-min-w-32 tw-min-w-20 tw-max-w-[10rem] lg:tw-gap-2">
                    <div className="tw-flex lg:tw-w-8 lg:tw-h-8 tw-w-4 tw-h-4 ">
                      <Image
                        src={"/images/general/clockOrange.svg"}
                        alt={"icon_clock"}
                        width={30}
                        height={30}
                        onError={(e) => {
                          e.currentTarget.src =
                            "/images/flight/checkout/imgNotFound.png";
                        }}
                      />
                    </div>
                    <div className="tw-flex tw-flex-col tw-items-center lg:tw-items-start lg:tw-gap-1 tw-text-orange-600  ">
                      <span className="lg:tw-text-xs tw-text-[11px] ">
                        {getLangLabel("Time to book")}
                        {":"}
                      </span>
                      <span className="tw-text-base lg:tw-text-xl tw-font-bold">
                        {countDown !== "00:00" && countDown}
                      </span>
                    </div>
                  </div>
                </>
              )}

              <div className="tw-flex tw-relative tw-justify-center tw-items-center lg:tw-hidden tw-min-w-14 tw-max-w-[10rem] tw-h-auto tw-min-h-2 tw-max-h-20 tw-text-orange-600">
                <QuestionMarkV2
                  codeVideo={showQuestionVideoHelp?.codeVideo}
                  isBurger={true}
                >
                  <div className="tw-w-10 tw-h-10">
                    <img
                      src="/images/general/hamburguer.svg"
                      alt=""
                      className="tw-w-10 tw-h-10"
                      onClick={() => handleToggle()}
                    />
                  </div>
                </QuestionMarkV2>
              </div>
            </div>
          )}
        </div>
      </nav>
    </section>
  );
}
