/* eslint-disable @next/next/no-img-element */
import { useHelpVideoStore } from "@store/helpVideoStore";

import { useLayoutStore } from "@store/layoutStore";
import { shallow } from "zustand/shallow";
import { useHelpInstructionsStore } from "@store/helpInstructionsStore";
import { hotels, resorts, stays } from "@data/videoHelp";
import { classNames } from "@helpers/helper";

type QuestionMarkPropsV2 = {
  children?: React.ReactNode;
  codeVideo?: string;
  isBurger?: boolean;
  isMenu?: boolean;
};

const QuestionMarkV2: React.FC<QuestionMarkPropsV2> = ({
  children,
  codeVideo,
  isBurger = false,
  isMenu = false,
}) => {
  const { setVideoCode } = useHelpVideoStore();
  const { setShowModal, setCodePath } = useHelpInstructionsStore();
  const { isEnabledVideoHelp, isEnabledInstructionHelp } = useLayoutStore(
    (state) => ({
      isEnabledVideoHelp: state.externalConfig?.isEnabledVideoHelp,
      isEnabledInstructionHelp: state.externalConfig?.isEnabledInstructionHelp,
    }),
    shallow
  );

  const findCode = (code: string) => {
    let isEnabled = hotels.some((hotel) => hotel.codeVideo === code);

    if (!isEnabled) {
      isEnabled = resorts.some((resort) => resort.codeVideo === code);
    }
    if (!isEnabled) {
      isEnabled = stays.some((stay) => stay.codeVideo === code);
    }

    return isEnabled;
  };
  if (codeVideo && findCode(codeVideo || "") && isEnabledInstructionHelp) {
    return (
      <>
        {children}
        <img
          src="/images/general/info-white.svg"
          alt="Icon"
          className={classNames(
            `tw-absolute tw-right-[4px] tw-cursor-pointer tw-transition-transform tw-hover:opacity-80 tw-hover:scale-110`,

            isMenu
              ? "tw-right-[20px] tw-top-4.5 tw-w-[15px] tw-h-auto"
              : isBurger
              ? "tw-right-11 sm:tw-right-11 tw-top-3 tw-w-[15px] tw-h-auto"
              : "tw-left-full tw-top-1/2 -tw-translate-y-1/2 tw-ml-1 tw-w-[15px] tw-h-[15px]"
          )}
          onClick={() => {
            setCodePath(codeVideo), setShowModal(true);
          }}
        />
      </>
    );
  }
  if (codeVideo && isEnabledVideoHelp) {
    return (
      <>
        {children}
        <img
          src="/images/general/video-mark.svg"
          alt=""
          className={classNames(
            `tw-absolute tw-cursor-pointer tw-transition-transform tw-hover:opacity-80 tw-hover:scale-110 `,
            isMenu
              ? "tw-right-5 tw-top-4.5 tw-w-[15px] tw-h-auto"
              : isBurger
              ? "tw-right-11 sm:tw-right-11 tw-top-3 tw-w-[15px] tw-h-auto"
              : "tw-left-[3rem] !-tw-right-4 !tw-top-1 !tw-w-[15px] !tw-h-[15px]"
          )}
          onClick={() => codeVideo && setVideoCode(codeVideo)}
        />
      </>
    );
  }

  return <>{children}</>;
};

export default QuestionMarkV2;
